import React, { FC } from 'react'

import { ProductCard, SectionContainer, SectionTitle } from '@alteos/ui'

import * as styles from './countrySelection.module.scss'

export type IProduct = React.ComponentProps<typeof ProductCard>

interface IProps {
  tiles: IProduct[]
  sectionTitle?: string
  id?: string
  dataTest?: string
}

const CountrySelection: FC<IProps> = ({ tiles, sectionTitle, id = '', dataTest = '' }) => (
  <SectionContainer noColumns noPaddingBottom dataTest={dataTest} id={id}>
    <div className="col-desk-12 col-mob-4 m-b-xxl">
      <SectionTitle value={sectionTitle} />
    </div>
    <div className={styles.wrapper}>
      {tiles.map(
        (
          { title, image, iconTint, backgroundPattern, backgroundColor, textColor, ctaLink },
          index
        ) => (
          <div className="col-desk-4 col-tab-6 col-mob-4" key={title}>
            <ProductCard
              title={title}
              image={image}
              iconTint={iconTint}
              backgroundPattern={backgroundPattern}
              backgroundColor={backgroundColor}
              textColor={textColor}
              ctaLink={ctaLink}
              dataTest={`country-card-${index}`}
            />
          </div>
        )
      )}
    </div>
  </SectionContainer>
)

export default CountrySelection
