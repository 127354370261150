import { graphql } from 'gatsby'
import queryString from 'query-string'
import React, { Dispatch, useEffect } from 'react'

import { SupportHeader, Footer, StatePageContainer } from '@alteos/ui'

import CountrySelection, { IProduct } from '../components/CountrySelection'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IContentfulCountrySelection, IRegistrierenByCountry } from '../types/registrieren'
import { getCtaParams } from '../utils/contentMaps/ctaContent'
import socialLinks from '../utils/socialLinks'

const mapContent = (
  data: IContentfulCountrySelection,
  setPopupState: Dispatch<React.SetStateAction<boolean>>
) => {
  const header = {
    items: data.headerContent.content.map((item) => ({
      to: item.ctaTo,
      label: item.ctaLabel,
    })),
  }

  const hero = {
    title: data.heroTitle.heroTitle,
  }

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content.map((item) => getCtaParams(item, setPopupState)),
    socialLinks,
  }

  const [
    {
      individualSection,
      mainTitle: { mainTitle: countriesTitle },
    },
  ] = data.mainContent

  const countryTiles = individualSection.map(
    ({
      mainTitle: { mainTitle: title },
      image,
      backgroundColor,
      textColor,
      pattern: backgroundPattern,
      cta: { ctaTo: to, ctaLabel: label },
    }) => ({
      title,
      image,
      backgroundColor,
      textColor,
      backgroundPattern,
      ctaLink: {
        targetBlank: true,
        to,
        label,
      },
    })
  )

  return {
    header,
    hero,
    countryTiles,
    countriesTitle,
    footer,
  }
}

const RegistrierenByCountry = ({ data, location }: IRegistrierenByCountry): JSX.Element => {
  useEffect(() => {
    sessionStorage.setItem('source', `${location.search || null}`)
  }, [location.search])

  return (
    <Layout>
      {({ setPopupState }) => {
        const content = mapContent(data.contentfulCountrySelection, setPopupState)

        return (
          <StatePageContainer>
            <>
              <SupportHeader {...content.hero} headerContent={content.header} />
              <CountrySelection
                id="countrySelection"
                sectionTitle={content.countriesTitle}
                tiles={content.countryTiles as IProduct[]}
                dataTest="CountrySelection"
              />
              <Footer dataTest="Footer" {...content.footer} />
              <Seo noIndex={false} />
            </>
          </StatePageContainer>
        )
      }}
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulCountrySelection {
      headerContent {
        content {
          ctaLabel
          ctaTo
        }
      }
      heroTitle {
        heroTitle
      }
      footerContent {
        copyright {
          copyright
        }
        content {
          ctaLabel
          ctaTo
        }
      }
      mainContent {
        mainTitle {
          mainTitle
        }
        individualSection {
          mainTitle {
            mainTitle
          }
          cta {
            ctaTo
            ctaLabel
          }
          backgroundColor
          textColor
          image {
            file {
              url
            }
          }
          pattern {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default RegistrierenByCountry
