
export interface ISocialLink {
  label: string
  to: string
  icon: string
}

const socialLinks = [
  {
    label: 'Linkedin',
    to: 'https://www.linkedin.com/company/alteostech/',
    icon: 'linkedin',
  },
  {
    label: 'Instagram',
    to: 'https://www.instagram.com/alteosberlin/',
    icon: 'instagram',
  },
]

export default socialLinks
